import { configureStore } from '@reduxjs/toolkit'

import remarks from './../modules/organization/candidate/slice/remarks'
import assessmentSchedules from './../modules/organization/jobs/slice/assessmentSchedules'
import job from './../modules/organization/jobs/slice/job'
import adjustmentSlice from './../modules/organization/manage-payments/slice/adjustmentSlice'
import assessmentSlice from '@/modules/organization/assessments/create-assessment/slice'
const store = configureStore({
    reducer: {
        assessmentSchedules: assessmentSchedules,
        job: job,
        adjustmentSlice: adjustmentSlice,
        remarks: remarks,
        createAssessment: assessmentSlice,
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
