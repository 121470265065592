import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

type section_type = {
    "id": string,
    "name": string,
    "scoring_options": [],
    "group_name": "FIELDS" | "OPTIONS" | "UPLOADS" | "UTILITY"
}

interface AssessmentState {
    assessment_id: string | null,
    version: string | undefined,
    name: string | undefined,
    sections: any[],
    settings: any,
    scoringMethod: any,
    assessmentId: string | null,
    section_types: {
        loading: true,
        data: section_type[] | null
    }
}

const initialState: AssessmentState = {
    version: undefined,
    assessment_id: null,
    name: 'Untitled',
    sections: [],
    settings: {},
    scoringMethod: {},
    assessmentId: null,
    section_types: {
        loading: true,
        data: null
    }
}

const createAssessment = createSlice({
    name: 'createAssessment',
    initialState,
    reducers: {
        updateAssessmentId(state, action: PayloadAction<string>) {
            state.assessment_id = action.payload
        },
        updateName(state, action: PayloadAction<string>) {
            state.name = action.payload
        },
        updateVersion(state, action: PayloadAction<string>) {
            state.version = action.payload
        },
        updateSections(state, action: PayloadAction<any[]>) {
            state.sections = action.payload
        },
        updateSettings(state, action: PayloadAction<any>) {
            state.settings = action.payload
        },
        updateScoringMethod(state, action: PayloadAction<any>) {
            state.scoringMethod = action.payload
        },
        updateSectionTypes(state, action: PayloadAction<any>) {
            state.section_types = action.payload
        }
    },
})

export const { updateName, updateVersion, updateSections, updateSettings, updateScoringMethod, updateSectionTypes, updateAssessmentId } = createAssessment.actions
export default createAssessment.reducer