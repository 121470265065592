import React from "react"
import { createSlice } from '@reduxjs/toolkit'
import { ASSESSMENT, RULE } from '../types'
import { getStatusClassNames } from "antd/es/_util/statusUtils"
const initialState: {
    assessments: ASSESSMENT[],
    meta: any,

} = {
    assessments: [], //type of assessment
    meta: null, //meta data for overall scores operators and modules 

}

export const assessmentSchedules = createSlice({
    name: 'assessmentSchedules',
    initialState,
    reducers: {

        setAssessments: (state, action) => {
            state.assessments = action.payload

        },
        setMeta: (state, action) => {
            state.meta = action.payload
        },
        updateRemark: (state, action) => {
            const { remark_id, rule_id, schedule_id, type } = action.payload
            const schedule = state.assessments.find(schedule => schedule.id == schedule_id)
            const index = schedule[type].findIndex(rule => rule.id == rule_id)
            schedule[type][index].remark_id = parseInt(remark_id)
        },
        updateOperator: (state, action) => {
            const { operator, rule_id, schedule_id, type } = action.payload
            const schedule = state.assessments.find(schedule => schedule.id == schedule_id)
            let index = schedule[type].findIndex(rule => rule.id == rule_id)
            if (index == -1) {
                index = schedule[type].findIndex(rule => rule.id == 0)
            }
            if (index != -1) {
                schedule[type][index].operator = operator
            }
        },

        updateAssessmentType: (state, action) => {
            const { assessment_id, assessment_type_id, individual_evaluation } = action.payload
            const schedule = state.assessments.find(schedule => schedule.id == assessment_id)
            if (schedule) {
                schedule.role_id = assessment_type_id
                const rules: RULE[] = []
                
                if (individual_evaluation) {
                    const { modules } = state.meta.roles.find((role: ROLE) => role.id == schedule?.role_id)
                    for (let i = 0; i < modules.length; i++) {
                        const { id, name } = modules[i];
                        const uniqueId = Date.now() + i; // Increment Date.now() by i

                        rules.push({
                            id: uniqueId,
                            module_id: id,
                            name: name,
                        });
                    }
                } else {
                    rules.push({
                        id: Date.now(),
                    })
                }
                schedule.rules = rules
            }
        },
        setRejectRules: (state, action) => {
            const { schedule_id } = action.payload
            const schedule = state.assessments.find(schedule => schedule.id == schedule_id)
            if (schedule) {
                const data = state.meta.roles.find(role => role.id == schedule.role_id)
                const rules: RULE[] = []
                if (data && data.modules) {
                    let { modules } = data
                    for (let i = 0; i < modules.length; i++) {
                        const { id, name } = modules[i];
                        const uniqueId = Date.now() + i; // Increment Date.now() by i

                        rules.push({
                            id: uniqueId,
                            module_id: id,
                            name: name,
                        });
                    }
                    schedule['reject_rules'] = [...rules]
                }
            }
        },
        createNewSchedule: state => {
            const schedule = {
                id: 'scd-' + Date.now(),
                role_id: undefined,
                sort: state.assessments.length,
                rules: [],
                reject_rules: [],
                editable: true,
            }

            state.assessments.push(schedule)
        },
        removeScheduleFromState: (state, action) => {
            state.assessments = state.assessments.filter(schedule => schedule.id !== action.payload)
        },
    },
})

export const {
    setAssessments,
    setMeta,
    updateRemark,
    updateOperator,
    updateAssessmentType,
    setRejectRules,
    createNewSchedule,
    removeScheduleFromState,
} = assessmentSchedules.actions

export default assessmentSchedules.reducer
