import { useEffect } from 'react'

function isClickInside(element, target) {
    if (element === target) {
        return true;
    }

    for (let child of element.children) {
        if (isClickInside(child, target)) {
            return true;
        }
    }

    return false;
}

function useOnClickOutside(ref, handler) {
    useEffect(

        () => {
            const listener = event => {
                let element = event.target

                // Do nothing if clicking ref's element or descendent elements
                const rect = ref.current?.getBoundingClientRect();
                if (!ref.current || ref.current.contains(element) || event.clientX >= rect.left &&
                    event.clientX <= rect.right &&
                    event.clientY >= rect.top &&
                    event.clientY <= rect.bottom) {
                    return
                }
                handler(event)


            }
            document.addEventListener('mousedown', listener)
            // document.addEventListener('touchstart', listener)
            return () => {
                document.removeEventListener('mousedown', listener)
                // document.removeEventListener('touchstart', listener)
            }
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler]
    )
}

export default useOnClickOutside
